<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2
            mt-5>
            <v-card
                light
                class="wrap-content gray-icons">
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <h2 class="primary--text font-weight-regular mb-3 pl-2 pt-4">
                        Extract Campaigns
                    </h2>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-stepper
                            v-model="e1"
                            light>
                            <v-stepper-header>
                                <v-stepper-step
                                    step="1"
                                    :complete="e1 > 1">
                                    Select Agency
                                </v-stepper-step>
                                <v-divider />
                                <v-stepper-step
                                    step="2"
                                    :complete="e1 > 2">
                                    Select Campaigns
                                </v-stepper-step>
                                <v-divider />
                                <v-stepper-step
                                    step="3"
                                    :complete="e1 > 3">
                                    Name and Build Play
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-autocomplete
                                                    v-model="selectedAgency"
                                                    :items="agencies"
                                                    item-text="name"
                                                    :loading="loadingAgencies"
                                                    label="Agency"
                                                    return-object
                                                    light />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        color="white"
                                                        large
                                                        @click.native="e1++">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-autocomplete
                                                    v-model="selectedAdAccount"
                                                    :items="adAccounts"
                                                    item-text="name"
                                                    :loading="loadingAdAccounts"
                                                    label="Ad Account"
                                                    return-object
                                                    light />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="e1--">
                                                        Back
                                                    </v-btn>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="e1++">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            v-for="campaign in campaigns"
                                            :key="campaign.id"
                                            row
                                            class="px-5 py-3">
                                            <v-flex xs12>
                                                <v-checkbox
                                                    :key="campaign.id"
                                                    v-model="campaign.selected"
                                                    :label="campaign.name"
                                                    :value="campaign.selected"
                                                    class="ma-0 mr-4 py-0" />
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-text-field
                                                    v-model="playName"
                                                    label="Play Name"
                                                    class="py-3 my-0"
                                                    required />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="e1--">
                                                        Back
                                                    </v-btn>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="extractCampaigns()">
                                                        Extract
                                                        Campaigns
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-list
                                                    v-for="update in updates.slice().reverse()"
                                                    :key="update.id"
                                                    light>
                                                    <v-list-tile
                                                        v-clipboard:copy="update.value"
                                                        v-clipboard:success="onCopy"
                                                        v-clipboard:error="onError"
                                                        avatar
                                                        @click.prevent>
                                                        <v-list-tile-content>
                                                            <v-list-tile-action-text>
                                                                {{ update.value }}...
                                                            </v-list-tile-action-text>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '../../http';
    import ApiQuery from '../../api-query';

    export default {
        data() {
            return {
                e1: null,
                loadingAgencies: true,
                selectedAgency: null,
                agencies: [],
                adAccounts: [],
                selectedAdAccount: null,
                loadingAdAccounts: false,
                loadingCampaigns: false,
                campaigns: [],
                updates: [],
                count: 0,
                playName: '',
            };
        },
        watch: {
            selectedAgency() {
                this.loadingAdAccounts = true;

                (new ApiQuery('agencies/' + this.selectedAgency.id + '/ad_accounts'))
                    .get()
                    .then(response => {
                        this.adAccounts = response.data.data;
                        this.loadingAdAccounts = false;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            selectedAdAccount() {
                this.loadingCampaigns = true;

                (new ApiQuery('agencies/' + this.selectedAgency.id + '/ad_accounts/' + this.selectedAdAccount.id + '/campaigns'))
                    .get()
                    .then(response => {
                        this.campaigns = response.data.data;
                        for (let i in this.campaigns) {
                            this.campaigns[i].selected = false;
                        }
                        this.loadingCampaigns = false;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        },
        mounted() {
            (new ApiQuery('agencies'))
                .setPageSize(1000)
                .setSortDirection('asc')
                .setSortField('agencies.name')
                .get()
                .then(response => {
                    this.agencies = response.data.data;
                    this.loadingAgencies = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        methods: {
            extractCampaigns() {
                let campaignIds = [];

                console.log(this.campaigns);

                for (let i in this.campaigns) {
                    if (this.campaigns[i].selected == true) {
                        campaignIds.push(this.campaigns[i].id);
                    }
                }

                (new HTTP)
                    .post(
                        '/agencies/' + this.selectedAgency.id + '/extract_campaigns_to_play',
                        {
                            play_name: this.playName,
                            campaign_ids: campaignIds,
                            activate_pusher: true
                        }
                    )
                    .then((response) => {
                        this.$flash('Campaign extraction started', 'green');
                        this.updates.push({ id: this.count, value: 'Campaign extraction started' });
                        this.count++;
                        this.$echo.channel('inventory-channel-' + response.data.data.uuid)
                            .listen('InventoryStatusUpdated', (e) => {
                                this.updates.push({ id: this.count, value: e.update });
                                this.count++;
                                console.log(e);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$flash(error.response.data.message, 'red');
                        this.updates.push(error.response.data.message);
                    });
            },
            onCopy(e) {
                this.$flash('\'' + e.text + '\' copied to clipboard!', 'green');
            },
            onError() {
                this.$flash('Failed to copy text.', 'red');
            }
        }
    };
</script>

